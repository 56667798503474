import React, { useState, useEffect } from "react";

import "./App.css";
import logo from "./vibes.svg"
import ColoredTable from "./ColoredTable";
import { STANDINGS_URL, STATS_URL } from "./constants";


const CATEGORIES = ["HR", "TB", "RBI", "RC", "SB", "OBP", "OPS", "HRA", "K", "QS", "ERA", "K/9", "WHIP", "SVHD"]


function App() {

    const [standings, setStandings] = useState([]);
    const [stats, setStats] = useState([]);

    useEffect(() => {
        fetch(STANDINGS_URL)
            .then(resp => resp.json())
            .then(data => setStandings(data))
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        fetch(STATS_URL)
            .then(resp => resp.json())
            .then(data => setStats(data))
            .catch(err => console.log(err));
    }, []);

    return (
        <div className="App container">
            <h1>Vibes League</h1>
            <img src={logo} width="10%" alt="vibes" style={{"marginTop": "-2%"}} />

            <ColoredTable data={standings} title="Standings & Luck"
                coloredColumns={["Win Pct.", "Luck (W)"]} />
            <ColoredTable data={stats} title="Cumulative Stats"
                coloredColumns={CATEGORIES} invertScale={["HRA", "ERA", "WHIP"]} />
        </div>
    );
}


export default App;
