import * as d3 from "d3";


function createColorScales(data, columns = [], invertScale=[]) {
    let scales = {};
    columns.forEach(c => {
        const extent = d3.extent(data.map(d => d[c]));
        const scale = d3.scaleSequential(d3.interpolateRdYlGn).domain(extent);
        if (invertScale.includes(c)) scale.domain([extent[1], extent[0]]);
        scales[c] = scale});
    return scales;
}


function ColoredTable({ data, coloredColumns, title, invertScale }) {
    const scales = createColorScales(data, coloredColumns, invertScale);
    const columns = Object.keys(data[0] || {});
    const headers = columns.map(d => <th key={d}>{d}</th>);
    const rows = data.map((d, i) => <ColoredTableRow data={d} scales={scales} key={i} />);
    return (
        <div className="row justify-content-center">
            <h3>{title}</h3>
            <div className="col">
                <table className="table table-sm">
                    <thead><tr>{ headers }</tr></thead>
                    <tbody>{ rows }</tbody>
                </table>
            </div>
        </div>
    );
}


function ColoredTableRow({ data, scales }) {

    function getBackground(column, value) {
        return scales[column] ? scales[column](value) : null;
    }

    function getColor(bg) {
        if (!bg) return "black";
        const rgb = bg.match(/\d+/g).map(s => parseInt(s));
        return (rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114) > 150 ? "black" : "white";
    }

    const cells = Object.entries(data).map(([k, v]) => {
        const bg = getBackground(k, v); 
        const txt = getColor(bg);
        return (
            <td key={k} style={{background: bg, color: txt}}>
                {v}
            </td>
        )
    });
    return (
        <tr>{ cells }</tr>
            
    );
}


export default ColoredTable;
